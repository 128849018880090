import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Profile from './Profile'
import Ccare from './components/customerCare'
import Menu from './components/menu'
import Register from './Register'
import VerifyEmail from './VerifyEmail';
import Login from './Login'
import {useState, useEffect} from 'react'
import {AuthProvider} from './AuthContext'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import PrivateRoute from './PrivateRoute'
import {Navigate} from 'react-router-dom'
import PaymentRoute from './components/cc_pay_rules'

function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log(user)
      setCurrentUser(user)
    })
  }, [])

  return (
    <Router>
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <Routes>
          <Route exact path='/' element={
            <PrivateRoute>
              <Menu/>
              <Ccare/>
            </PrivateRoute>
          }/>
           <Route exact path='/ccare' element={
            <PrivateRoute>
               <Menu/>
              <Profile/>
            </PrivateRoute>
          }/>
          <Route path="/login" element={
            !currentUser?.emailVerified 
            ? <Login/>
            : <Navigate to='/' replace/>
          } />
           <Route exact path='/pay_rules' element={
            <PrivateRoute>
               <Menu/>
              <PaymentRoute/>
            </PrivateRoute>
          }/>
          {/*<Route path="/register" element={
            !currentUser?.emailVerified 
            ? <Register/>
            : <Navigate to='/' replace/>
          } />
        <Route path='/verify-email' element={<VerifyEmail/>} /> */}
        </Routes>  
      </AuthProvider>
  </Router>
  );
}

export default App;

import React, { useState,useEffect  } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useForm, Controller  } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import '../css/searchForm.css'; // Importa il file CSS

export default function FormPropsTextFields(props) {
    
    const { register, handleSubmit, control, setValue   } = useForm();

    const [fromdata, setFromdata] = React.useState(null);
    const [todata, setTodata] = React.useState(null);

    useEffect(() => {  
        const values = []

        props.dataSet(values)

    },[])





    const onSubmit = (data) => {
   
        props.emptydata()
        const cardholderName = data.name && data.surname ? data.name + " " + data.surname : null
        var newData = {
            cardholderName : data.name && data.surname ? data.name + " " + data.surname : undefined,
            last4 : data.last4 ? data.last4 : undefined,
            first6 : data.first6 ? data.first6 : undefined,
            clickid: data.clickid ? data.clickid : undefined,
            email: data.email ? data.email : undefined,
            from: data.from ? data.from : undefined,
            to: data.to ? data.to : undefined,
        }

        // http://localhost:2222/ccare/getUser

        fetch("https://payment.trkmyclick.com/ccare/getUser", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
        .then(response => response.json()) 
        .then(response => {
            
            props.dataSet(response)
        })
        .catch((err)=>{
            console.log(err)
        })
        return true
    }

    

  return (
      <>
   <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} className="form-container">
                <Grid item xs={12} md={10}>
                    <Controller
                        name={"textValue"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormLabel component="legend">Search for a Customer</FormLabel>
                                <div className="input-group">
                                    <TextField {...register('name')} id="name" label="Name" type="search" />
                                    <TextField {...register('surname')} id="surname" label="Surname" type="search" />
                                    <TextField {...register('email')} id="email" label="Email" type="search" />
                                    <TextField {...register('last4')} id="last4" label="Last 4 Digits" type="search" />
                                    <TextField {...register('first6')} id="first6" label="First 6 Digits" type="search" />
                                    <TextField {...register('clickid')} id="clickid" label="Click ID" type="search" />
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div className="date-picker-group">
                                        <DesktopDatePicker
                                            label="Date From"
                                            id="from"
                                            inputFormat="yyyy-MM-dd"
                                            value={fromdata}
                                            onChange={(value) => { setValue('from', value); setFromdata(value) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <DesktopDatePicker
                                            label="Date To"
                                            id="to"
                                            inputFormat="yyyy-MM-dd"
                                            value={todata}
                                            onChange={(value) => { setValue('to', value); setTodata(value) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button variant="contained" size="large" type="submit">Search</Button>
                </Grid>
            </Grid>
        </form>
    </>
  );
}
// RoutingRulesManager.js
import React, { useEffect, useState } from 'react';
import '../css/payment_route.css'; // Importa il file CSS

const RoutingRulesManager = () => {
    const [rules, setRules] = useState([]);

    const [services, setServices] = useState([]);
    const [countries, setCountries] = useState([]);
    const [sources, setSources] = useState([]);
    const [gateway, setGateway] = useState([]);
    const [filteredRules, setFilteredRules] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const [newRule, setNewRule] = useState({
        service: '',
        country: '',
        rateplan: '',
        binIssuer: '',
        binSchema: '',
        source: '',
        riskFactor: '',
        gateway: ''
    });

    useEffect(() => {
        fetchRules();
    }, []);

    const url = window.location.host == 'localhost:3000' ? 'http://localhost:2222' : 'https://payment.trkmyclick.com';
  

    const fetchRules = async () => {
        try {
            const response = await fetch(url+'/ccare/pay_rules');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRules(data.rules);
            setFilteredRules(data.rules);
            setServices(data.service); // Estrai i servizi
            setCountries(data.country); // Estrai i paesi
            setSources(data.sources); // Estrai le fonti
            setGateway(data.gateway); // Estrai le fonti
        } catch (error) {
            console.error('Error fetching rules:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRule({ ...newRule, [name]: value });
    };

    const handleAddRule = async () => {

        setSubmitted(true);

        // Controllo se il gateway è selezionato
        if (!newRule.gateway) {
            
            return; // Non procedere se il gateway non è selezionato
        }

        try {
            const response = await fetch(url+'/ccare/pay_rules', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newRule),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const addedRule = await response.json();
            setNewRule({
                service: '',
                country: '',
                rateplan: '',
                binIssuer: '',
                binSchema: '',
                source: '',
                riskFactor: '',
                gateway: ''
            });
            resetForm(); // Resetta il modulo dopo l'aggiunta
            setSubmitted(false); // Resetta lo stato di submitted dopo 
            fetchRules(); // Ricarica le regole
        } catch (error) {
            console.error('Error adding rule:', error);
        }
    };


    const resetForm = () => {
        setNewRule({
            service: '',
            country: '',
            rateplan: '',
            binIssuer: '',
            binSchema: '',
            acquisitionChannel: '',
            riskFactor: '',
            gateway: ''
        });
    };

    const handleDeleteRule = async (id) => {
        try {
            const response = await fetch(url+`/ccare/pay_rules/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchRules(); // Ricarica le regole
        } catch (error) {
            console.error('Error deleting rule:', error);
        }
    };

    const handleEditRule = (rule) => {
        setNewRule(rule); // Popola il modulo con i dati della regola da modificare
    };


     // Funzione per filtrare le regole in base alla query di ricerca
     const handleFilterChange = (e) => {
        const query = e.target.value.toLowerCase();

        if (query === '') {
            // Se il campo di filtro è vuoto, mostra tutte le regole
            setFilteredRules(rules);
        } else {
            // Filtra le regole in base alla query
            const filtered = rules.filter(rule =>
                Object.values(rule).some(value =>
                    String(value).toLowerCase().includes(query)
                )
            );
            console.log(filtered);
            // Aggiorna le regole filtrate
            setFilteredRules(filtered.length > 0 ? filtered : []); // Mostra solo se ci sono corrispondenze
        }
    };

    return (<>
        <div className="container">
            <h2>Gestione Regole Gateway</h2>

            <h3>Aggiungi Regola</h3>
            <select className="select" name="service" value={newRule.service} onChange={handleChange}>
                <option value="">Seleziona Servizio</option>
                {Array.from(new Set(services)).map((service, index) => (
                    <option key={index} value={service}>{service}</option>
                ))}
            </select>
            <select className="select" name="country" value={newRule.country} onChange={handleChange}>
                <option value="">Seleziona Paese</option>
                {Array.from(new Set(countries)).map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                ))}
            </select>
            <input type="text" name="rateplan" placeholder="Piano Tariffario" value={newRule.rateplan} onChange={handleChange} />
            <input type="text" name="binIssuer" placeholder="BIN Issuer" value={newRule.binIssuer} onChange={handleChange} />
            <input type="text" name="binSchema" placeholder="BIN Schema" value={newRule.binSchema} onChange={handleChange} />
            <select className="select" name="source" value={newRule.source} onChange={handleChange}>
                <option value="">Seleziona Source</option>
                {Array.from(new Set(sources)).map((source, index) => (
                    <option key={index} value={source}>{source}</option>
                ))}
            </select>
           
            <input type="text" name="riskFactor" placeholder="Fattore di Rischio" value={newRule.riskFactor} onChange={handleChange} />
            <select className="select" name="gateway" value={newRule.gateway} onChange={handleChange}>
                <option value="">Seleziona Gateway</option>
                {Array.from(new Set(gateway)).map((gateway, index) => (
                    <option key={index} value={gateway}>{gateway}</option>
                ))}
            </select>
            {submitted && !newRule.gateway && (
                    <span style={{ color: 'red' }}> Gateway Obbligatorio</span> // Indica che è obbligatorio solo se non selezionato
                )}
            <button onClick={handleAddRule}>Aggiungi Regola</button>

          
        </div>
        <div className="container" style={{ marginTop: '30px'}}>   

            <h3>Regole Esistenti</h3>
            <input 
                type="text" 
                placeholder="Filtra..." 
                onChange={handleFilterChange} 
                style={{ marginBottom: '20px', padding: '10px', width: '100%', borderRadius: '4px', border: '1px solid #ccc' }} 
            />
            <ul>
                {filteredRules.map(rule => (
                    <li key={rule._id} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
                        <ul>
                        {Object.entries(rule).map(([key, value]) => (
                            key !== '_id' && key !== '__v' && (
                               <li key={key}  style={{ padding: '5px'}}>
                                <strong>{key}: </strong> <span style={{ marginLeft: '10px'}}>{value}</span>
                                </li> 
                            )
                            
                        ))}
                        </ul>
                        <button onClick={() => handleEditRule(rule)}>Modifica</button>
                        <button onClick={() => handleDeleteRule(rule._id)}>Rimuovi</button>
                    </li>
                ))}
                 {filteredRules.length === 0 && (
                    <li style={{ padding: '10px', color: '#999' }}>Nessuna regola trovata.</li>
                )}
            </ul>
        </div>
        </>);
};

export default RoutingRulesManager;
